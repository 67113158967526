<template>
	<div class="exception-body notfound">
		<div class="exception-panel"></div>

		<div class="exception-content">
			<!-- <img src="layout/images/logo-black.png" alt="babylon-layout"/> -->
			<h1><span class="exception-name">Página</span> não encontrada</h1>
			<p>Requested resource is not available.</p>
		</div>
	</div>
</template>

<script>

</script>

<style scoped>

</style>